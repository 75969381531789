.container {
    width: 95vw;
    max-width: 900px;
    min-width: 360px;
    margin: 0 auto;
    padding: 3rem 0 1.25rem 0;
}

.fc-button-primary, .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #293b8f;
}

.fc-event, .fc-event:hover, .fc-event:active {
    font-weight: bold;
}

.fc-timegrid-event-harness:nth-of-type(even) .fc-event  {
    background-color: #ffdf0c;
    border-color: #d0b504;
    color: #293b8f !important;
}

.fc-daygrid-event-harness:nth-of-type(even) .fc-event  {
    background-color: #ffdf0c;
    border-color: #d0b504;
    color: #293b8f !important;
}

.fc-daygrid-event-harness {
    overflow: hidden;
}

.fc-timegrid-event-harness:nth-of-type(odd) .fc-event {
    color: #ffdf0c;
    border-color: #2b3871;
    background-color: #293b8f;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fc-timegrid-event-harness:nth-of-type(even) .fc-event .fc-event-main {
    color: #293b8f;
}

.fc-daygrid-event-harness:nth-of-type(odd) .fc-event {
    color: #ffdf0c;
    border-color: #2b3871;
    background-color: #293b8f;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fc .fc-event.gcal-events {
    z-index: 4;
}

.fc-left h2 {
    padding: 0 0.5rem;
    font-size: 1.25rem;
    font-weight: bold;
}

@media (max-width: 767px ) {
    .fc-toolbar {
        font-size: 0.8rem;
    }
}

@media (min-width: 576px) {
    .fc-toolbar {
        font-size: 0.7rem;
    }

 }
