nav ul {
   display: flex;
   justify-content: center;
   align-items: center;
   list-style-type: none;
   margin: 2rem 0 1rem 0;
   padding: 0;
   flex-wrap: wrap;
}
nav > ul > li {
    margin: 0.5rem;
    line-height: 25px;
}

nav > ul > li > a {
    padding: .5rem 1rem;
    background-color: #293b8f;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 0.4rem;
    white-space: nowrap;
}

nav > ul > li > .active {
    background-color: #ffdf0c;
    color:#293b8f; 
}

@media (max-width: 500px) {
    nav > ul {
        flex-direction: column;
    }
    nav > ul > li > a {
        display: block;
        min-width: 80vw;
    }
}

@media (max-width: 370px) {
    nav > ul {
        margin: 2rem 0 .5rem 0;
    }
    nav > ul > li > a  {
        padding: .5rem;
        font-size: 0.8rem;
    }
}