.App {
  text-align: center;
  min-width: 360px;
}

.App-header {
  box-shadow: 0 0.1rem .3rem #ddd;
}

.App-link {
  color: #333;
  font-weight: bold;
}


.App-logo {
  height: 10vmin;
  pointer-events: none;
  margin: 1.5rem 0;
}
